@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.not-found {
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  background: #fff;
  .not-found-text-wrapper {
    h1 {
      padding-bottom: vw_d(30);
      font-size: vw_d(205);
      line-height: vw_d(205);
      font-family: $narkis-bold;
      text-align: center;
    }
    p {
      font-size: vw_d(30);
      line-height: vw_d(34);
      font-family: $narkis-regular;
      text-align: center;
    }
  }
}
