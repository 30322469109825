@import 'src/routes/client/styles/index.scss';

.form-submit-error-modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.message {
  padding-bottom: vw_d(20);
  text-align: center;
  font-size: vw_d(25);

  @include screen('tablet-portrait') {
    padding-bottom: vw_t(20);
    font-size: vw_t(25);
  }

  @include screen('mobile') {
    padding-bottom: vw(20);
    font-size: vw(20);
  }
}

.submit-btn {
  height: vw_d(40);
  width: vw_d(180);
  font-size: vw_d(19);

  @include screen('tablet-portrait') {
    height: vw_t(40);
    width: vw_t(180);
    font-size: vw_t(19);
  }

  @include screen('mobile') {
    height: vw(40);
    width: vw(180);
    font-size: vw(19);
  }
}
