@import 'src/routes/client/styles/index.scss';

.error-label {
  font-size: vw_d(15);
  line-height: 1;
  color: rgba(252, 68, 79, 1);
  font-family: $narkis-regular;
  padding: vw_d(5) 0 0 0;

  @include screen('tablet-portrait') {
    padding: vw_t(5) 0 0 0;
    font-size: vw_t(14);
  }

  @include screen('mobile') {
    padding: vw(5) 0 0 0;
    font-size: vw(14);
  }
}
