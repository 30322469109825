@import 'src/routes/client/styles/index.scss';

.button {
  background-color: var(--sendButtonColor);
  font-family: $narkis-light;
  color: #fff;
  transition: opacity 0.2s ease-in-out;
  padding: 0 vw_d(22);

  @include screen('tablet-portrait') {
    padding: 0 vw_t(22);
  }

  @include screen('mobile') {
    padding: 0 vw(22);
  }

  @include screen('desktop') {
    &:hover {
      opacity: 0.8;
    }
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
