@import 'src/routes/client/styles/index.scss';

.conditions {
  position: relative;
  flex-grow: 1;
  padding-left: vw_d(20);
  @include screen('tablet-portrait') {
    padding-left: vw_t(20);
  }
  @include screen('mobile') {
    padding-left: vw(20);
    margin-bottom: vw(20);
  }

  label {
    display: flex;
    // align-items: center;
    width: fit-content;

    .input-wrapper {
      margin-top: vw_d(7);
      position: relative;
      width: vw_d(23);
      height: vw_d(23);
      min-width: vw_d(23);
      margin-left: vw_d(10);
      overflow: hidden;
      @include screen('tablet-portrait') {
        margin-top: vw_t(7);
        height: vw_t(18);
        width: vw_t(18);
        min-width: vw_t(18);
        margin-left: vw_t(10);
      }

      @include screen('mobile') {
        margin-top: vw(7);
        height: vw(18);
        width: vw(18);
        min-width: vw(18);
        margin-left: vw(10);
      }
      &.with-error {
        span {
          border: solid 1px rgba(252, 68, 79, 1);
        }
      }
      input {
        position: absolute;
        left: -1000px;
        top: -100px;
      }
      span {
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid rgba(168, 168, 168, 1);
        background-color: transparent;
        cursor: pointer;
        &:after {
          content: '';
          position: absolute;
          display: none;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          @include screen('tablet-portrait') {
            width: vw_t(5);
            height: vw_t(10);
          }

          @include screen('mobile') {
            width: vw(5);
            height: vw(10);
          }
        }
      }

      input:checked + span {
        background-color: var(--sendButtonColor);
        border-color: var(--sendButtonColor);

        &:after {
          display: block;
        }
      }
    }
    p {
      font-size: vw_d(19);
      color: var(--fieldTextColor);
      @include screen('tablet-portrait') {
        font-size: vw_t(19);
      }
      @include screen('mobile') {
        font-size: vw(19);
      }
      a {
        color: var(--linkTextColor);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .error {
    position: absolute;
    @include screen('mobile') {
      padding-top: 0;
    }
  }
}
