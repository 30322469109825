@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.top-section {
  width: 100%;

  .image-wrapper {
    width: 100%;
    height: vw_d(630);

    @include screen('tablet-portrait') {
      height: vw_t(380);
    }

    @include screen('mobile') {
      height: vw(279);
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
