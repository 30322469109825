@import 'src/routes/client/styles/index.scss';

.container {
  position: relative;
  width: 100%;

  &.is-error {
    .input-wrapper {
      input {
        border: solid 1px rgba(252, 68, 79, 1);
      }
    }
  }

  .input-wrapper {
    position: relative;
    input {
      display: block;
      width: 100%;
      padding: 0 vw_d(14) 0 vw_d(28);
      font-size: vw_d(19);
      font-weight: normal;
      height: vw_d(41);
      line-height: vw_d(19);
      font-family: $narkis-regular;
      color: var(--fieldTextColor);
      outline: none;
      // border-radius: 0;
      border: none;
      border: 1px solid rgba(168, 168, 168, 1);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0;
      background-image: none;

      &:-internal-autofill-selected,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        border: 1px solid rgba(168, 168, 168, 1);
      }

      @include screen('tablet-portrait') {
        padding: 0 vw_t(11) 0 vw_t(15);
        font-size: vw_t(19);
        font-weight: normal;
        height: vw_t(34);
        line-height: vw_t(19);
      }

      @include screen('mobile') {
        padding: 0 vw(11) 0 vw(15);
        font-size: vw(19);
        font-weight: normal;
        height: vw(34);
        line-height: vw(19);
      }

      &::placeholder {
        color: var(--fieldTextColor);
      }
    }
    .label {
      position: absolute;
      top: vw_d(11);
      right: vw_d(14);
      padding: 0 vw_d(2);
      font-size: vw_d(19);
      line-height: vw_d(19);
      font-weight: normal;
      font-family: $narkis-regular;
      color: var(--fieldTextColor);
      background-color: #fff;
      pointer-events: none;
      transition: all 0.2s ease;
      @include screen('tablet-portrait') {
        top: vw_t(9);
        right: vw_t(11);
        padding: 0 vw_t(2);
        font-size: vw_t(19);
        line-height: vw_t(19);
        font-weight: normal;
      }

      @include screen('mobile') {
        top: vw(8);
        right: vw(11);
        padding: 0 vw(2);
        font-size: vw(19);
        line-height: vw(19);
        font-weight: normal;
      }
    }

    &.focused {
      .label {
        top: vw_d(-6);
        font-size: vw_d(14);
        line-height: vw_d(14);
        @include screen('tablet-portrait') {
          top: vw_t(-6);
          font-size: vw_t(14);
          line-height: vw_t(14);
        }
        @include screen('mobile') {
          top: vw(-6);
          font-size: vw(14);
          line-height: vw(14);
        }
      }
    }
  }
}
