@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.texts-section {
  display: flex;
  justify-content: space-around;
  width: 74.73vw;
  margin: 0 auto;
  padding: vw_d(50) vw_d(20);
  padding-top: vw_d(20);

  @include screen('tablet-portrait') {
    padding: vw_t(18) vw_t(20);
    padding-top: vw_t(10);
    width: 100%;
  }

  @include screen('mobile') {
    padding: vw(18) vw(20);
    width: 100%;
  }

  .wrapper {
    h1 {
      padding-bottom: vw_d(10);
      font-size: 2vw;
      line-height: 2.8vw;
      font-family: $narkis-bold;
      color: var(--formTitleTextColor);
      text-align: center;

      @include screen('mobile') {
        padding-bottom: vw_t(5);
        font-size: vw_t(22);
        line-height: 1.3;
      }

      @include screen('mobile') {
        padding-bottom: vw(5);
        font-size: vw(22);
        line-height: 1.3;
      }
    }
    p {
      font-size: 1.4vw;
      line-height: 1.56vw;
      font-family: $narkis-light;
      color: var(--formTitleTextColor);
      text-align: center;

      @include screen('tablet-portrait') {
        font-size: vw_t(15);
        letter-spacing: -0.6px;
        line-height: 1.1;
      }

      @include screen('mobile') {
        font-size: vw(15);
        letter-spacing: -0.6px;
        line-height: 1.1;
      }
    }
    ol,
    ul {
      li {
        font-size: 1.4vw;
        line-height: 1.56vw;
        font-family: $narkis-light;
        color: var(--formTitleTextColor);
        text-align: center;

        @include screen('tablet-portrait') {
          font-size: vw_t(15);
          letter-spacing: -0.6px;
          line-height: 1.1;
        }

        @include screen('mobile') {
          font-size: vw(15);
          letter-spacing: -0.6px;
          line-height: 1.1;
        }
      }
    }
  }
}
