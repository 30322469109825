@import 'src/routes/client/styles/index.scss';

.select {
  position: relative;

  &.with-error {
    :global {
      .rc__control {
        border-radius: 0;
        border-color: rgba(252, 68, 79, 1);

        &:focus {
          border-color: rgba(252, 68, 79, 1);
        }
        &:hover {
          border-color: rgba(252, 68, 79, 1);
        }

        &:active {
          border-color: rgba(252, 68, 79, 1);
        }

        &--is-focused {
          border-color: rgba(252, 68, 79, 1);
        }
      }
    }
  }

  :global {
    .rc__control {
      min-height: vw_d(41);
      border-radius: 0;
      border-color: rgba(168, 168, 168, 1);

      @include screen('tablet-portrait') {
        min-height: vw_t(34);
      }

      @include screen('mobile') {
        min-height: vw(34);
      }

      &:focus {
        border-color: rgba(168, 168, 168, 1);
      }
      &:hover {
        border-color: rgba(168, 168, 168, 1);
      }

      &:active {
        border-color: rgba(168, 168, 168, 1);
      }

      &--is-focused {
        border-color: rgba(168, 168, 168, 1);
        box-shadow: none;
      }
    }

    .rc__value-container {
      padding: 0 vw_d(14);
      font-size: vw_d(19);

      @include screen('tablet-portrait') {
        padding: 0 vw_t(11) 0 vw_t(14);
        font-size: vw_t(19);
      }

      @include screen('mobile') {
        padding: 0 vw(11) 0 vw(14);
        font-size: vw(19);
      }

      .rc__placeholder {
        color: var(--fieldTextColor);
      }
    }

    .rc__single-value {
      color: var(--fieldTextColor);
      margin: 0;
    }
  }

  .dropdown-indicator {
    padding: vw_d(9);
    display: flex;
    align-items: center;
    justify-content: center;

    @include screen('tablet-portrait') {
      padding: vw_t(6);
    }

    @include screen('mobile') {
      padding: vw(6);
    }

    span {
      width: vw_d(20);
      height: vw_d(20);
      position: relative;

      @include screen('tablet-portrait') {
        width: vw_t(20);
        height: vw_t(20);
      }

      @include screen('mobile') {
        width: vw(20);
        height: vw(20);
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%) rotate(-45deg);
        width: vw_d(10);
        height: vw_d(10);
        border-bottom: 1px solid rgba(168, 168, 168, 1);
        border-left: 1px solid rgba(168, 168, 168, 1);

        @include screen('tablet-portrait') {
          width: vw_t(10);
          height: vw_t(10);
        }

        @include screen('mobile') {
          width: vw(10);
          height: vw(10);
        }
      }
    }
  }
}

:global {
  .rc__menu {
    border-radius: 0 !important;
    border: 1px solid rgba(168, 168, 168, 1) !important;
    box-shadow: none !important;
    margin-top: -1px !important;
    // margin-bottom: vw_d(8) !important;

    // @include screen('tablet-portrait') {
    //   margin-top: vw_t(8) !important;
    //   margin-bottom: vw_t(8) !important;
    // }

    // @include screen('mobile') {
    //   margin-top: vw(8) !important;
    //   margin-bottom: vw(8) !important;
    // }
  }
  .rc__option {
    font-size: vw_d(19) !important;
    padding: vw_d(6) vw_d(14) !important;

    @include screen('tablet-portrait') {
      font-size: vw_t(19) !important;
      padding: vw_t(2) vw_t(11) !important;
    }

    @include screen('mobile') {
      font-size: vw(19) !important;
      padding: vw(2) vw(11) !important;
    }

    &--is-focused {
      background-color: rgba(168, 168, 168, 0.05) !important;
    }
    &--is-selected {
      background-color: rgba(168, 168, 168, 0.2) !important;
      color: var(--fieldTextColor) !important;
    }
  }
}

.select-wrapper {
  .label {
    position: absolute;
    top: vw_d(11);
    right: vw_d(14);
    padding: 0 vw_d(2);
    font-size: vw_d(19);
    line-height: vw_d(19);
    font-weight: normal;
    font-family: $narkis-regular;
    color: var(--fieldTextColor);
    background-color: #fff;
    pointer-events: none;
    transition: all 0.2s ease;
    z-index: 20;
    @include screen('tablet-portrait') {
      top: vw_t(9);
      right: vw_t(11);
      padding: 0 vw_t(2);
      font-size: vw_t(19);
      line-height: vw_t(19);
      font-weight: normal;
    }

    @include screen('mobile') {
      top: vw(8);
      right: vw(11);
      padding: 0 vw(2);
      font-size: vw(19);
      line-height: vw(19);
      font-weight: normal;
    }
  }

  &.focused {
    .label {
      top: vw_d(-6);
      font-size: vw_d(14);
      line-height: vw_d(14);
      @include screen('tablet-portrait') {
        top: vw_t(-6);
        font-size: vw_t(14);
        line-height: vw_t(14);
      }
      @include screen('mobile') {
        top: vw(-6);
        font-size: vw(14);
        line-height: vw(14);
      }
    }
  }
}
