@import 'src/routes/client/styles/index.scss';

.container {
  position: relative;
  width: 100%;

  &.isError {
    .texarea-wrapper {
      textarea {
        border: solid 1px rgba(252, 68, 79, 1);
      }
    }
  }
  .texarea-wrapper {
    textarea {
      display: block;
      width: 100%;
      padding: vw_d(11) vw_d(14);
      font-size: vw_d(19);
      font-weight: normal;
      line-height: 1.05;
      font-family: $narkis-regular;
      color: var(--fieldTextColor);
      outline: none;
      border-radius: 0;
      border: 1px solid rgba(168, 168, 168, 1);
      resize: none;

      &:-internal-autofill-selected,
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        border: 1px solid rgba(168, 168, 168, 1);
      }

      @include screen('mobile') {
        padding: vw(11) vw(11) vw(11) vw(14);
        font-size: vw(19);
      }

      @include screen('tablet-portrait') {
        padding: vw_t(11) vw_t(11) vw_t(11) vw_t(14);
        font-size: vw_t(19);
      }

      &::placeholder {
        color: var(--fieldTextColor);
      }
    }
    .label {
      position: absolute;
      top: vw_d(11);
      right: vw_d(14);
      padding: 0 vw_d(2);
      font-size: vw_d(19);
      line-height: vw_d(19);
      font-weight: normal;
      font-family: $narkis-regular;
      color: var(--fieldTextColor);
      background-color: #fff;
      pointer-events: none;
      transition: all 0.2s ease;
      @include screen('tablet-portrait') {
        top: vw_t(9);
        right: vw_t(11);
        padding: 0 vw_t(2);
        font-size: vw_t(19);
        line-height: vw_t(19);
        font-weight: normal;
      }

      @include screen('mobile') {
        top: vw(8);
        right: vw(11);
        padding: 0 vw(2);
        font-size: vw(19);
        line-height: vw(19);
        font-weight: normal;
      }
    }

    &.focused {
      .label {
        top: vw_d(-6);
        font-size: vw_d(14);
        line-height: vw_d(14);
        @include screen('tablet-portrait') {
          top: vw_t(-6);
          font-size: vw_t(14);
          line-height: vw_t(14);
        }
        @include screen('mobile') {
          top: vw(-6);
          font-size: vw(14);
          line-height: vw(14);
        }
      }
    }
  }
}
