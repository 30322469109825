@import 'src/routes/client/styles/index.scss';

.success-modal {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.message {
  padding: 0 0 vw_d(10);
  font-size: vw_d(28);
  color: #000;
  font-family: $narkis-medium;

  @include screen('tablet-portrait') {
    padding: 0 0 vw_t(10);
    font-size: vw_t(28);
  }

  @include screen('mobile') {
    padding: 0 0 vw(10);
    font-size: vw(28);
  }
}

.submit-btn {
  height: vw_d(40);
  width: vw_d(180);
  font-size: vw_d(19);

  @include screen('tablet-portrait') {
    height: vw_t(40);
    width: vw_t(180);
    font-size: vw_t(19);
  }

  @include screen('mobile') {
    height: vw(40);
    width: vw(180);
    font-size: vw(19);
  }
}
