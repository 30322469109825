@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: vw_d(100);
  padding: 0 vw_d(40);
  z-index: 2;
  @include screen('mobile') {
    height: vw(80);
    padding: 0 vw(10);
  }

  .socials-wrapper {
    display: flex;
  }

  .logos {
    display: flex;
    align-items: center;
    .logo {
      width: vw_d(70);
      margin-left: vw_d(20);
      @include screen('tablet') {
        width: vw_t(60);
        margin-left: vw_t(20);
      }
      @include screen('mobile') {
        width: vw(60);
        margin-left: vw(20);
      }
      &.without-link {
        cursor: inherit;
        pointer-events: none;
      }
      &:last-child {
        margin-left: 0;
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
}
