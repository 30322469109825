@import 'src/routes/client/styles/index.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    max-width: vw_d(400);
    width: 100%;
    padding: vw_d(25);
    z-index: 2;
    font-size: vw_d(19);
    position: relative;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    @include screen('tablet-portrait') {
      max-width: vw_t(400);
      padding: vw_t(25);
      font-size: vw_t(19);
    }

    @include screen('mobile') {
      max-width: vw(320);
      padding: vw(25);
      font-size: vw(19);
    }
  }

  .close-btn {
    position: absolute;
    top: vw_d(5);
    right: vw_d(5);
    width: vw_d(30);
    height: vw_d(30);
    opacity: 1;
    transition: opacity 0.2s ease-in;

    @include screen('tablet-portrait') {
      top: vw_t(5);
      right: vw_t(5);
      width: vw_t(30);
      height: vw_t(30);
    }

    @include screen('mobile') {
      top: vw(5);
      right: vw(5);
      width: vw(30);
      height: vw(30);
    }

    &:hover {
      opacity: 0.8;
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: vw_d(20);
      background-color: #000;

      @include screen('tablet-portrait') {
        height: vw_t(20);
      }

      @include screen('mobile') {
        height: vw(20);
      }
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(5px);
  }

  .title {
    padding: 0 0 vw_d(10);
    font-size: vw_d(28);
    color: #000;
    font-family: $narkis-medium;

    @include screen('tablet-portrait') {
      padding: 0 0 vw_t(10);
      font-size: vw_t(28);
    }

    @include screen('mobile') {
      padding: 0 0 vw(10);
      font-size: vw(28);
    }
  }
}
