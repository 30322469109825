@import 'src/routes/client/styles/index.scss';

.call-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: vw_d(45);
  width: vw_d(257);
  padding: 0 vw_d(22);
  margin: 0 auto;
  font-size: vw_d(23);
  background-color: transparent;
  border: 1px solid var(--sendButtonColor);
  font-family: $narkis-light;
  color: var(--sendButtonColor);
  transition: opacity 0.2s ease-in-out;

  @include screen('tablet-portrait') {
    height: vw_t(42);
    width: vw_t(257);
    padding: 0 vw_t(22);
    font-size: vw_t(18);
  }

  @include screen('mobile') {
    height: vw(42);
    width: 100%;
    padding: 0 vw(22);
    font-size: vw(18);
  }

  @include screen('desktop') {
    &:hover {
      opacity: 0.8;
    }
  }

  .phone-icon {
    width: vw_d(20);
    height: vw_d(20);
    margin-left: vw_d(10);
    @include screen('tablet-portrait') {
      width: vw_t(20);
      height: vw_t(20);
      margin-left: vw_t(10);
    }
    @include screen('mobile') {
      width: vw(20);
      height: vw(20);
      margin-left: vw(10);
    }
    svg {
      display: block;
      width: 100% !important;
      height: 100% !important;
    }
  }
}
