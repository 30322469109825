@import 'src/routes/client/styles/index.scss';

.form {
  width: vw_d(1300);
  margin: 0 auto;
  padding: 0 0 vw_d(60);

  @include screen('tablet-portrait') {
    width: vw_t(800);
  }

  @include screen('mobile') {
    width: 100%;
    padding: 0 vw(20) vw(60);
  }

  .form-content {
    padding-top: vw_d(10);
    width: 100%;
    overflow: hidden;
    @include screen('mobile') {
      padding-top: vw(10);
    }
  }

  .fields {
    .fields-inner {
      width: 100%;
      display: flex;
      justify-content: center;
      row-gap: vw_d(25);
      column-gap: vw_d(25);
      flex-wrap: wrap;
      padding: 0 0 vw_d(40);

      @include screen('tablet-portrait') {
        row-gap: vw_t(20);
        column-gap: vw_t(20);
        padding: 0 0 vw_t(40);
      }

      @include screen('mobile') {
        row-gap: vw(20);
        column-gap: vw(20);
        padding: 0 0 vw(40);
        justify-content: space-between;
      }

      .field-wrapper {
        &.short {
          width: vw_d(130);

          @include screen('tablet-portrait') {
            width: 30%;
          }

          @include screen('mobile') {
            width: 47%;
          }
        }
        &.medium {
          width: vw_d(306);

          @include screen('tablet-portrait') {
            width: vw_t(180);
          }

          @include screen('mobile') {
            width: 100%;
          }
        }
        &.long {
          width: 100%;
        }
      }
    }
  }
  .submit-and-conditions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: vw_d(20);
    @include screen('tablet-portrait') {
      padding-bottom: vw_t(20);
    }

    @include screen('mobile') {
      flex-wrap: wrap;
      padding-bottom: vw(20);
    }

    &.content-center {
      justify-content: center;
    }
  }
  .submit-btn {
    height: vw_d(45);
    width: vw_d(257);
    font-size: vw_d(23);
    flex-shrink: 0;

    @include screen('tablet-portrait') {
      height: vw_t(42);
      width: vw_t(257);
      font-size: vw_t(18);
    }

    @include screen('mobile') {
      height: vw(42);
      width: 100%;
      font-size: vw(18);
    }
  }
}
