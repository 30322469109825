@import 'src/routes/client/styles/index.scss';

.date-picker {
  position: relative;

  &.with-error {
    .input-wrapper {
      input {
        border-color: rgba(252, 68, 79, 1);
      }
    }
  }

  .hidden-input {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
  }
  // input {
  //   display: block;
  //   width: 100%;
  //   padding: 0 vw_d(14) 0 vw_d(28);
  //   font-size: vw_d(19);
  //   font-weight: normal;
  //   height: vw_d(41);
  //   line-height: vw_d(19);
  //   font-family: $narkis-regular;
  //   color: var(--fieldTextColor);
  //   outline: none;
  //   border-radius: 0;
  //   border: 1px solid rgba(168, 168, 168, 1);

  //   @include screen('tablet-portrait') {
  //     padding: 0 vw_t(11) 0 vw_t(15);
  //     font-size: vw_t(19);
  //     height: vw_t(34);
  //     line-height: vw_t(19);
  //   }

  //   @include screen('mobile') {
  //     padding: 0 vw(11) 0 vw(15);
  //     font-size: vw(19);
  //     height: vw(34);
  //     line-height: vw(19);
  //   }

  //   &::placeholder {
  //     color: var(--fieldTextColor);
  //   }
  // }
  .input-wrapper {
    input {
      display: block;
      width: 100%;
      padding: 0 vw_d(14) 0 vw_d(28);
      font-size: vw_d(19);
      font-weight: normal;
      height: vw_d(41);
      line-height: vw_d(19);
      font-family: $narkis-regular;
      color: var(--fieldTextColor);
      outline: none;
      border-radius: 0;
      border: 1px solid rgba(168, 168, 168, 1);

      @include screen('tablet-portrait') {
        padding: 0 vw_t(11) 0 vw_t(15);
        font-size: vw_t(19);
        height: vw_t(34);
        line-height: vw_t(19);
      }

      @include screen('mobile') {
        padding: 0 vw(11) 0 vw(15);
        font-size: vw(19);
        height: vw(34);
        line-height: vw(19);
      }

      &::placeholder {
        color: var(--fieldTextColor);
      }
    }
    .label {
      position: absolute;
      top: vw_d(11);
      right: vw_d(14);
      padding: 0 vw_d(2);
      font-size: vw_d(19);
      line-height: vw_d(19);
      font-weight: normal;
      font-family: $narkis-regular;
      color: var(--fieldTextColor);
      background-color: #fff;
      pointer-events: none;
      transition: all 0.2s ease;
      @include screen('tablet-portrait') {
        top: vw_t(9);
        right: vw_t(11);
        padding: 0 vw_t(2);
        font-size: vw_t(19);
        line-height: vw_t(19);
        font-weight: normal;
      }

      @include screen('mobile') {
        top: vw(8);
        right: vw(11);
        padding: 0 vw(2);
        font-size: vw(19);
        line-height: vw(19);
        font-weight: normal;
      }
    }

    &.focused {
      .label {
        top: vw_d(-6);
        font-size: vw_d(14);
        line-height: vw_d(14);
        @include screen('tablet-portrait') {
          top: vw_t(-6);
          font-size: vw_t(14);
          line-height: vw_t(14);
        }
        @include screen('mobile') {
          top: vw(-6);
          font-size: vw(14);
          line-height: vw(14);
        }
      }
    }
  }
}

/* mobiscroll styles */
.mb-datepicker {
  font-family: $narkis-regular;
  font-size: vw_d(18);

  @include screen('tablet-portrait') {
    font-size: vw_t(19);
  }

  @include screen('mobile') {
    font-size: vw(19);
  }

  :global {
    .mbsc-datepicker .mbsc-calendar {
      width: vw_d(339) !important;

      @include screen('tablet-portrait') {
        width: vw_t(310) !important;
      }

      @include screen('mobile') {
        width: vw(310) !important;
      }
    }

    .mbsc-font {
      font-size: vw_d(19);

      @include screen('mobile') {
        font-size: vw(19);
      }
    }

    .mbsc-popup-body {
      border-radius: 0;
      border: 1px solid rgba(168, 168, 168, 1);
      box-shadow: 0 0 9px 0 rgba(57, 110, 228, 0.1);

      @include screen('tablet-portrait') {
        box-shadow: 0 0 vw_t(9) 0 rgba(57, 110, 228, 0.1);
      }

      @include screen('mobile') {
        box-shadow: 0 0 vw(9) 0 rgba(57, 110, 228, 0.1);
      }
    }

    .mbsc-popup-buttons {
      font-family: $narkis-regular;
    }

    .mbsc-calendar-header .mbsc-button {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      padding: vw_d(4) 0 vw_d(6);
      font-family: $narkis-medium;
      font-size: vw_d(20);
      line-height: 1.3;
      letter-spacing: -0.33px;
      color: var(--fieldTextColor);

      @include screen('tablet-portrait') {
        padding: vw_t(4) 0 vw_t(6);
        font-size: vw_t(20);
      }

      @include screen('mobile') {
        padding: vw(4) 0 vw(6);
        font-size: vw(20);
      }
    }

    .mbsc-calendar-month-inner {
      font-family: $narkis-regular;
    }

    .mbsc-calendar-day-inner {
      font-family: $narkis-regular;
    }

    .mbsc-calendar-year-inner {
      font-family: $narkis-regular;
    }
    .mbsc-calendar-day-inner {
      font-family: $narkis-regular;
    }

    .mbsc-calendar-year {
      &.mbsc-selected {
        .mbsc-calendar-year-text {
          color: #000;
          background-color: rgba(168, 168, 168, 0.2);
          border-color: rgba(168, 168, 168, 0.2);
        }
      }
    }

    .mbsc-calendar-month {
      &.mbsc-selected {
        .mbsc-calendar-month-text {
          color: #000;
          background-color: rgba(168, 168, 168, 0.2);
          border-color: rgba(168, 168, 168, 0.2);
        }
      }
    }

    .custom-nav {
      pointer-events: none;
    }

    .custom-nav .mbsc-icon {
      display: none;
    }

    .mbsc-calendar-title,
    .mbc-button {
      font-family: $narkis-medium;
      font-size: vw_d(20);
      line-height: 1.3;
      letter-spacing: -0.33px;
      color: #030303;

      @include screen('tablet-portrait') {
        font-size: vw_t(20);
      }

      @include screen('mobile') {
        font-size: vw(20);
      }
    }

    .mbsc-calendar-week-day {
      font-family: $narkis-bold;
      height: vw_d(40);
      line-height: 1;
      font-size: vw_d(19);
      color: #000;

      @include screen('tablet-portrait') {
        height: vw_t(40);
        font-size: vw_t(19);
      }

      @include screen('mobile') {
        height: vw(40);
        font-size: vw(19);
      }
    }

    .mbsc-calendar-day-text {
      font-family: $narkis-regular;
      color: #000;
      font-size: vw_d(14);
      line-height: vw_d(34);
      width: vw_d(34);
      height: vw_d(34);
      margin: 0 auto;

      @include screen('tablet-portrait') {
        font-size: vw_t(14);
        line-height: vw_t(34);
        width: vw_t(34);
        height: vw_t(34);
      }

      @include screen('mobile') {
        font-size: vw(14);
        line-height: vw(34);
        width: vw(34);
        height: vw(34);
      }

      &[aria-pressed='true'] {
        color: #000;
      }
    }

    .mbsc-selected .mbsc-calendar-day-text,
    .mbsc-hover .mbsc-calendar-day-text {
      font-size: vw_d(14) !important;
      line-height: vw_d(34) !important;
      width: vw_d(34) !important;
      height: vw_d(34) !important;
      margin: 0 auto !important;
      color: #000;
      background: rgba(168, 168, 168, 0.2);
      border-color: rgba(168, 168, 168, 0.2);

      @include screen('tablet-portrait') {
        font-size: vw_t(14) !important;
        line-height: vw_t(34) !important;
        width: vw_t(34) !important;
        height: vw_t(34) !important;
      }

      @include screen('mobile') {
        font-size: vw(14) !important;
        line-height: vw(34) !important;
        width: vw(34) !important;
        height: vw(34) !important;
      }
    }

    .mbsc-calendar-day::after,
    .mbsc-range-hover::before {
      height: 86%;
      top: 7%;
      border: none;
      background-color: rgba(168, 168, 168, 0.2);
      opacity: 0.2;
    }

    .mbsc-icon > svg {
      fill: #000;
    }

    .mbsc-hover .mbsc-calendar-cell-text {
      background-color: rgba(168, 168, 168, 0.2);
    }

    .mbsc-disabled .mbsc-calendar-cell-text {
      color: #9b9b9b;
      opacity: 1;
    }

    /* input styles */
    .mbsc-material.mbsc-textfield-wrapper {
      width: vw_d(240);
      height: vw_d(42);
      padding: vw_d(7) vw_d(15) vw_d(7) vw_d(7);
      border: vw_d(2) solid #fff;
      border-radius: vw_d(24);
      background-color: #fff;
      font-size: vw_d(18);
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      @include screen('tablet-portrait') {
        width: vw_t(240);
        height: vw_t(42);
        padding: vw_t(7) vw_t(15) vw_t(7) vw_t(7);
        border: vw_t(2) solid #fff;
        border-radius: vw_t(24);
        font-size: vw_t(18);
      }

      @include screen('mobile') {
        width: vw(240);
        height: vw(42);
        padding: vw(7) vw(15) vw(7) vw(7);
        border: vw(2) solid #fff;
        border-radius: vw(24);
        font-size: vw(18);
      }
    }

    .mbsc-material.mbsc-textfield-box.mbsc-select,
    .mbsc-material.mbsc-textfield-inner-box {
      background: white;
    }

    .mbsc-calendar-day-outer .mbsc-calendar-day-text,
    .mbsc-calendar-day-outer .mbsc-calendar-month-name {
      opacity: 1;
    }
  }
}
