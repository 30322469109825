@import 'src/routes/client/styles/index.scss';

.checkbox {
  position: relative;
  font-size: vw_d(19);
  cursor: pointer;
  height: vw_d(41);

  line-height: 1;

  @include screen('tablet-portrait') {
    height: vw_t(34);
    column-gap: vw_t(10);
  }

  @include screen('mobile') {
    font-size: vw(19);
    height: vw(34);
    column-gap: vw(10);
  }

  &.with-error {
    .check-mark {
      border: 1px solid rgba(252, 68, 79, 1);
    }
  }

  .main {
    display: flex;
    column-gap: vw_d(10);
    align-items: center;
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked ~ .check-mark {
      background-color: var(--sendButtonColor);
      border-color: var(--sendButtonColor);

      &:after {
        display: block;
      }
    }
  }

  .check-mark {
    position: relative;
    display: block;
    height: vw_d(23);
    width: vw_d(23);
    flex-shrink: 0;
    background-color: transparent;
    border: 1px solid rgba(168, 168, 168, 1);

    @include screen('tablet-portrait') {
      height: vw_t(18);
      width: vw_t(18);
    }

    @include screen('mobile') {
      height: vw(18);
      width: vw(18);
    }

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 40%;
      top: 25%;
      transform: translate(-50%, -50%);
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      @include screen('tablet-portrait') {
        left: 35%;
        top: 15%;
        width: vw_t(5);
        height: vw_t(10);
      }

      @include screen('mobile') {
        left: 35%;
        top: 15%;
        width: vw(5);
        height: vw(10);
      }
    }
  }

  .label-text {
    color: var(--fieldTextColor);
  }

  .error {
    @include screen('tablet-portrait') {
      bottom: vw_t(-10);
    }

    @include screen('mobile') {
      bottom: vw(-10);
    }
  }
}
