@import 'src/routes/client/styles/index.scss';
@import 'src/routes/client/styles/theme';
@import 'src/routes/client/styles/mixins';

.socials {
  position: relative;
  z-index: 51;
  display: flex;
  height: 100%;
  cursor: pointer;

  .social-button {
    display: inline-block;
    width: vw_d(32);
    margin-right: vw_d(20);
    align-items: center;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s;
    line-height: 0;

    @include screen('tablet') {
      width: vw_t(30);
    }

    svg {
      width: 100%;
      height: auto;
    }

    img {
      width: 100%;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .overflow-wrap {
    display: flex;
    align-items: center;
  }

  &.minified {
    @include screen('mobile') {
      margin-right: vw(14.5);
    }

    &.open {
      .overflow-wrap {
        visibility: visible;
        opacity: 1;

        @include screen('mobile') {
          position: absolute;
          top: vw(50);
        }
      }
    }

    .overflow-wrap {
      position: relative;
      transition: all 0.25s ease-in-out;
      visibility: hidden;
      opacity: 0;

      @include screen('mobile') {
        position: absolute;
        top: vw(-20);
        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: vw(46);
        height: vw(100);
        transition: all 0.3s ease;
        background: #f4f5fa;
        box-shadow: 0 12px 40px 0 rgba(21, 37, 154, 0.23);
      }
    }

    .social-button {
      width: vw(30);
      margin: 0;
    }

    .icon {
      overflow: visible;
      &-facebook {
        width: vw(30);
      }

      &-whatsapp {
        width: vw(30);
        margin-left: 0;
      }

      &-toggle {
        width: inherit;
        height: inherit;
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .toggle-btn {
      position: relative;
      z-index: 1;
      width: vw(30);
      height: vw(30);
      border-radius: 50%;
      transition: 0.3s;
      overflow: visible;

      svg {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}
